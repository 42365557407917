import React from "react"
import Img from "gatsby-image"

const AboutHalf = ({ fields }) => {
  const { description, heading, image, icons } = fields;

  return (
    <section className="aboutHalf">
      <div className="container">
        <div className="aboutHalf__wrapper">
          <div className="aboutHalf__half aboutHalf__half--img">
            {image.imageFile? (
              <Img fluid={image.imageFile.childImageSharp.fluid} />
            ):''}
          </div>
          <div className="aboutHalf__half aboutHalf__half--content">
            <h2 className="aboutHalf__heading heading heading--dash heading--big">
              <span dangerouslySetInnerHTML={{ __html: heading }} />
            </h2>
            <div className="content">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            {icons && (
              <div className="aboutHalf__icons">
                {icons.map((icon) => (
                  <div key={icon.icon.databaseId} className="aboutHalf__icons__item">
                    <Img fluid={icon.icon.imageFile.childImageSharp.fluid} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
export default AboutHalf
