import React from "react"
import Img from "gatsby-image"
import Button from "../button/Button";

const AboutProducts = ({ fields }) => {
  const { button, heading, image } = fields;

  return (
    <section className="aboutProducts">
      <div className="aboutProducts__img">
        <Img fluid={image.imageFile.childImageSharp.fluid} />
      </div>
      <div className="aboutProducts__wrapper">
        <div className="container">
          <h2 className="aboutProducts__heading heading heading--dash heading--big">
            <span dangerouslySetInnerHTML={{ __html: heading }} />
          </h2>
          <div className="aboutProducts__button">
            <Button title={button.title} url={button.url} target={button.target} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default AboutProducts
