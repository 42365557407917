import React from "react"
import AboutHalf from "./aboutHalf";
import AboutHero from "./AboutHero"
import "./style.scss"
import { withPrefix } from 'gatsby';
import AboutProducts from "./AboutProducts";



const About = ({ acf }) => {
  return (
    <>
      <AboutHero fields={acf.hero} />
      <div className="about__half__wrapper" style={{
        backgroundImage: `url(${withPrefix('/images/product_cat_bg.png')})`,
        backgroundPosition: '-15% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
      }}>
        <AboutHalf fields={acf.create} />
        <AboutHalf fields={acf.quality} />
        <AboutHalf fields={acf.company} />
      </div>
      <AboutProducts fields={acf.products} />
    </>
  )
}
export default About
