import React, { useState } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getOgImage } from "../../utils/functions"
import About from "../../components/about/About"
import { WPViewContent } from "../../utils/WPpixel"

const AboutPage = props => {
  const {
    pageContext: { title, seo, uri, content, acfAbout },
  } = props
  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)
  
  useState(()=>{
    WPViewContent('View');
  },[])

  return (
    <Layout lang={lang} page="about" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"about"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          <div className="about page">
            <section className="description">
              <div className="container">
                <div className="page__hero">
                  <h1 className="heading heading--xl">{title}</h1>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </div>
              </div>
            </section>
            <About lang={lang} acf={acfAbout} />
          </div>
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default AboutPage
