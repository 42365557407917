import React from "react"
import Img from "gatsby-image"

const AboutHero = ({ fields }) => {
  const { description, heading, img } = fields;

  return (
    <section className="aboutHero">
      <div className="container">
        <div className="aboutHero__img">
          <Img fluid={img.imageFile.childImageSharp.fluid} />
        </div>
        <div className="aboutHero__wrapper">
          <h2 className="aboutHero__heading heading heading--center heading--dash heading--big">
            {heading}
          </h2>
          <div className="content">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default AboutHero
